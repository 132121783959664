<template>
  <div ref="swiperBox"  class="swiperBox">
    <!-- swiper -->
    <swiper class="swiper-top" :options="swiperOption" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl" ref="swiperTop">
      <swiper-slide v-for="sliderImage in images" :key="sliderImage._id" >
          <div class="product-image" :style="'background: url(' + sliderImage.image + '); background-position: center; background-repeat: no-repeat;'"></div>
      </swiper-slide>
    </swiper>
    <!-- swiper2 Thumbs -->
    <swiper class="swiper gallery-thumbs mt-3 lg:block hidden" :options="swiperOptionThumbs"  ref="swiperThumbs">
      <swiper-slide v-for="sliderImage in images" :key="sliderImage._id" >
        <div class="thumb-image" :style="'background: url(' + sliderImage.image + ');background-size:cover; background-repeat: no-repeat;'"></div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
  import 'swiper/dist/css/swiper.min.css'
  import { swiper, swiperSlide } from 'vue-awesome-swiper'

  export default{
    name:"Carousel",
    props:['images'],
    components: {
      swiper,
      swiperSlide
    },
    data(){
      return {
        // Related Products Swiper
        swiperOption: {
          spaceBetween: 10,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          loop: true,
          loopedSlides: this.optionForLoopNumber('loopedSlides')
        },
         swiperOptionThumbs: {
           loop: this.optionForLoopNumber('loop'),
          // loopedSlides: this.optionForLoopNumber('loopedSlides'), 
          // slidesPerView: this.optionForLoopNumber('slidesPerView'),
          // touchRatio: 0.2,
          // slideToClickedSlide: true
          spaceBetween: 10,
          slidesPerView: 'auto',
          slidesPerGroup: 1,
        }
      }
    },
    mounted() {
      this.$nextTick(() => {
        const swiperTop = this.$refs.swiperTop.swiper
        const swiperThumbs = this.$refs.swiperThumbs.swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
      })
    },
    methods: {
      optionForLoopNumber(options){
        const loop = this.images.length === 1 ? false : true
        const loopedSlides = this.images.length > 2 ? '3' : '2'
        const slidesPerView = this.images.length > 2 ? '4' : '3'
        if (options === 'loop') return loop
        if (options === 'loopedSlides') return loopedSlides
        if (options === 'slidesPerView') return slidesPerView
       
      }
    }
  }
</script>

<style scoped>
  .swiperBox{
    width:100%;
    position: relative;
  }
  .swiper_btn{
    position: absolute;
  }
  .product-image{
    max-width: 445px;
    max-height:445px;
    background-size:contain !important;
    height: 22rem;
  }
  .gallery-thumbs {
      height: 20%;
      box-sizing: border-box;
      padding: 0;
    }
    .thumb-image{
      height: 82px; 
    }
  .gallery-thumbs .swiper-slide {
          width: 100%;
    max-height: 110px;
    max-width: 110px;
    height: 100%;
      opacity: 0.4;
    }
  .gallery-thumbs .swiper-slide-active {
      opacity: 1;
    }
    @media (min-width: 1025px){
      .product-image{
        background-size:cover !important;
         height: 32rem;
      }
      .thumb-image{
        height: 110px; 
      }
      
    }
  /*.swiper-button-prev{*/
  /*  width: 0.36rem;*/
  /*  height: 0.28rem;*/
  /*  !*background: url("path") no-repeat;*!*/
  /*  background-size: 100% 100%;*/
  /*}*/
  /*.swiper-button-next{*/
  /*  width: 0.36rem;*/
  /*  height: 0.28rem;*/
  /*  !*background: url("path") no-repeat;*!*/
  /*  background-size: 100% 100%;*/
  /*}*/
</style>
